import {
    AppName,
    IAngularApp,
    IAppConfig,
} from "@codewise/voluum-frontend-core/app";
import { EventBus as EventBusFactory } from "@codewise/voluum-frontend-framework/event_bus_client/event_bus";
import { Observable, Observer } from "rxjs";

import { AppLoadStrategyService, IAppLoadStrategy } from "./app_load_strategy";

export interface IApp {
    getAppName: () => string;
    getModuleName: () => string;
    bootstrap: (...args) => void;
    setEventBus: (eventBusFactory: EventBusFactory) => void;
}

type AppConstructor = {
    new (config: IAppConfig): IAngularApp;
} & {
    default: {
        new (config: IAppConfig): IAngularApp;
    };
};

export class Apps {
    public static getActiveApps(
        config: IAppConfig,
        appLoadStrategy: IAppLoadStrategy = "ALL"
    ): [keyof IAppConfig["apps"], string][] {
        return Object.entries<string | false>(config.apps)
            .filter(([appName, _]) => appName !== "voluumFrontendPortal") // Temporarily filter out portal app
            .filter(([_, appUrl]) => appUrl !== false)
            .filter(([appName, _]) => {
                if (appLoadStrategy === "FIRST_LOAD") {
                    return AppLoadStrategyService.isFirstLoadApp(appName);
                } else if (appLoadStrategy === "FIRST_LOAD_REST") {
                    return !AppLoadStrategyService.isFirstLoadApp(appName);
                } else {
                    return true;
                }
            }) as [keyof IAppConfig["apps"], string][];
    }

    public static instantiate(
        config: IAppConfig,
        appLoadStrategy?: IAppLoadStrategy
    ): IAngularApp[] {
        return Apps.getActiveApps(config, appLoadStrategy)
            .map(([appName]) => {
                const appConstructor = getGlobalVar<AppConstructor>(appName);
                if (
                    appConstructor &&
                    appConstructor.default instanceof Function
                ) {
                    return appConstructor.default;
                }
                return appConstructor;
            })
            .map((appConstructor) => new appConstructor(config));
    }

    public static getActiveAppNames(
        config: IAppConfig,
        appLoadStrategy?: IAppLoadStrategy
    ): AppName[] {
        return Apps.getActiveApps(config, appLoadStrategy).map(([appName]) => {
            return appName
                .replace("voluumFrontend", "")
                .toLowerCase() as AppName;
        });
    }

    public static load(
        config: IAppConfig,
        appLoadStrategy?: IAppLoadStrategy
    ): Observable<void>[] {
        const appUrls: string[] = Apps.getActiveApps(
            config,
            appLoadStrategy
        ).map(([_, appUrl]) => appUrl);
        return appUrls.map(loadScript);
    }
}

function getGlobalVar<T = unknown>(name: string): T {
    return window[name];
}

function loadScript(src: string): Observable<void> {
    return new Observable((observer: Observer<void>): void => {
        const onload = () => {
            observer.next();
            observer.complete();
        };
        const onerror = (error: ErrorEvent) => observer.error(error);
        appendToDocumentHead(
            toScriptEl({
                src,
                onload,
                onerror,
                async: "async",
            })
        );
    });
}

function toScriptEl(attrs: Record<string, unknown>): HTMLScriptElement {
    "use strict";
    const scriptEl: HTMLScriptElement = document.createElement("script");
    for (const attribute in attrs) {
        if (attrs.hasOwnProperty(attribute)) {
            scriptEl[attribute] = attrs[attribute];
        }
    }
    return scriptEl;
}

function appendToDocumentHead(element: HTMLElement): Node {
    return document.head.appendChild(element);
}
